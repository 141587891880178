import React, { useState, useEffect } from 'react';
import { NavLink, useRoutes, Outlet } from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Toaster, toast } from 'react-hot-toast';
import { Popover, PopoverBody } from 'reactstrap';
import Avatar from 'react-avatar';
// CHECK FOR EXPIRED COOKIE SESSION
import { useLazyQuery } from '@apollo/client';
// END OF CHECK FOR EXPIRED COOKIE SESSION

import { APIENDPOINT, ROLES, axiosInstance } from './config';
import { logout } from './actions/appSlice';
import './App.scss';
import addIcons from './actions/addIcons';
// import logo from './assets/img/logo.png';
import logo from './assets/img/main-logo.png';
import logoAdmin from './assets/img/logo_admin.png';
import iconApp from './assets/img/icon-app.svg';
import iconDashboard from './assets/img/icon-dashboard-normal-active.svg';
// import iconManifest from './assets/img/icon-manifest_active.svg';
import iconReport from './assets/img/icon-report-normal-black_active.svg';
import iconMaps from './assets/img/map.svg';
import iconSearch from './assets/img/icon-smol-search-black_active.svg';
import iconUpload from './assets/img/icon-upload_active.svg';
// import iconLink from './assets/img/icon-upload-from-url_active.svg';
import iconUser from './assets/img/icon-identity-mgt_active.svg';
import iconLink from './assets/img/icon-upload-from-url-admin_active.svg';
import networkIcon from './assets/img/network.svg';
import MIB_ASE_NetworkIcon from './assets/img/MIB_ASE_network.svg';
import MaskGroup from './assets/img/maskGroup.png';
// import MaskGroup from './assets/img/maskGroup.svg';
import iconExpandNode from './assets/img/icon-expand-node.svg';
import iconChatBlack from './assets/img/icon-chat-black.svg';
import iconManifest from './assets/img/icon-manifest.svg';
import iconGuessNode from './assets/img/icon-guess-node.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'react-circular-progressbar/dist/styles.css';

// CHECK FOR EXPIRED COOKIE SESSION
import { GET_THREATLIST } from './views/DataExtraction/DataBrowser/Threats/Threats';
// import { handleError } from './actions/networkActions';
// END OF CHECK FOR EXPIRED COOKIE SESSION

addIcons();

const Login = React.lazy(() => import('./views/Login/Login'));
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const Sources = React.lazy(() => import('./views/Sources/Sources'));
const Analysis = React.lazy(() => import('./views/Analysis/Analysis'));
const DataExtraction = React.lazy(() => import('./views/DataExtraction/DataExtraction'));
const ReportBuilderIframeComponent = React.lazy(() => import('./views/DataExtraction/PacketBuilder/PacketBuilderComponents/ReportBuilderIframeComponent'));
// const PacketBuilderPrintable = React.lazy(() => import('./views/DataExtraction/PacketBuilder/PacketBuilderPrintable'));
const Governance = React.lazy(() => import('./views/Governance/Governance'));
const Admin = React.lazy(() => import('./views/Admin/Admin'));

const DndApp = React.lazy(() => import('./views/_Playground/DndApp'));

function Layout() {
  return (
    <>
      <Toaster position='top-right' />
      <Outlet />
    </>
  )
}
function AdminLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const username = useSelector((state) => state.app.username);
  const role = useSelector((state) => state.app.role);
  const isSidebarOpen = useSelector((state) => state.app.isSidebarOpen);
  // const expandCollapseSidebar = () => {
  //   isSidebarOpen ? dispatch(collapseSidebar()) : dispatch(expandSidebar());
  // };

  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false); // State to manage sidebar collapse

  const logOut = async () => {
    try {
      await axiosInstance({
        url: `${APIENDPOINT}/logout`,
        method: 'POST',
      });
      toast.custom(
        <div
          className="alert alert-light d-flex align-items-center justify-content-start shadow-sm"
          style={{ width: "350px" }}
        >
          <div>
            <FontAwesomeIcon
              icon="check-circle"
              size="2x"
              className="me-3 text-vsepr"
            />
          </div>
          <div>
            <h6 className="fw-bold mb-0 text-vsepr">
              Successfully Logged Out
            </h6>
          </div>
        </div>
      );
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(logout());
    }
  };

  function renderNav() {
    return (
      <nav
        className={`nav flex-column mt-4 ${isSidebarCollapsed ? "collapsed-sidebar" : ""
          }`}
      >
        {/* Navigation items */}
        <li className="nav-item" title="Dashboard">
          <NavLink to="/" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? "active bg-vseprmenuactive" : ""}`}>
            <img alt="" src={iconDashboard} className="me-2" />
            {!isSidebarCollapsed && <span>Dashboard</span>}
          </NavLink>
        </li>
        {!isSidebarCollapsed ?
          <li className="nav-item">
            <span className="text-vseprdark nav-link fw-bold text-uppercase py-2">Sources</span>
          </li> :
          <hr className="mx-2 my-1 text-muted"></hr>
        }
        {/* <li className="nav-item">
          <NavLink to="/sources/sourceconfiguration" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconLink} className="me-2" />
            <span>Source Configuration</span>
          </NavLink>
        </li> */}


        <li className="nav-item" title="Data Import">
          <NavLink to="/sources/dataimport" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center  ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconUpload} className="me-2" />
            {!isSidebarCollapsed && <span>Data Import</span>}
          </NavLink>
        </li>

        {!isSidebarCollapsed ?
          <li className="nav-item">
            <span className="text-vseprdark nav-link fw-bold text-uppercase py-2">Analysis</span>
          </li> :
          <hr className="mx-2 my-1 text-muted"></hr>
        }
        <li className="nav-item" title="Data Discovery">
          <NavLink to="/analysis/datadiscovery" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconExpandNode} className="me-2" />
            {!isSidebarCollapsed && <span>Data Discovery</span>}
          </NavLink>
        </li>

        <li className="nav-item" title="Potential Relationships">
          <NavLink to="/analysis/potentialrelationships" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconGuessNode} className="me-2" />
            {!isSidebarCollapsed && <span>Potential Relationships</span>}
          </NavLink>
        </li>

        <li className="nav-item" title="Brainstorm">
          <NavLink to="/analysis/brainstorm" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconChatBlack} className="me-2" />
            {!isSidebarCollapsed && <span>Brainstorm</span>}
          </NavLink>
        </li>
        {role === ROLES.ADMIN &&
          <li className="nav-item" title="Manifest">
            <NavLink to="/analysis/manifest" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
              <img alt="" src={iconManifest} className="me-2" />
              {!isSidebarCollapsed && <span>Manifest</span>}
            </NavLink>
          </li>
        }
        {role === ROLES.ADMIN &&
          <li className="nav-item" title="Packet Builder">
            <NavLink to="/analysis/maps" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
              <img alt="" src={iconMaps} className="me-2" />
              {!isSidebarCollapsed && <span>Maps</span>}
            </NavLink>
          </li>
        }
        {!isSidebarCollapsed ?
          <li className="nav-item">
            <span className="text-vseprdark nav-link fw-bold text-uppercase py-2">Data Extraction</span>
          </li> :
          <hr className="mx-2 my-1 text-muted"></hr>
        }
        <li className="nav-item" title="Data Browser">
          <NavLink to="/dataextraction/databrowser" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconSearch} className="me-2" />
            {!isSidebarCollapsed && <span>Data Browser</span>}
          </NavLink>
        </li>
        {role === ROLES.ADMIN &&
          <li className="nav-item" title="Packet Builder">
            <NavLink to="/dataextraction/packets" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
              <img alt="" src={iconReport} className="me-2" />
              {!isSidebarCollapsed && <span>Packet Builder</span>}
            </NavLink>
          </li>
        }
        {/* <li className="nav-item">
          <span className="text-vseprdark nav-link fw-bold text-uppercase py-2">Governance</span>
        </li> */}
        {/* <li className="nav-item">
          <NavLink to="/governance/extractionrequests" className={({ isActive }) => `btn btn-outline-vseprmenuactive rounded-0 text-start text-muted nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-vseprmenuactive' : ''}`}>
            <img alt="" src={iconManifest} className="me-2" />
            <span>Extraction Requests</span>
          </NavLink>
        </li> */}
        {/* Additional navigation items */}
      </nav>
    );
  }

  const [loading, setLoading] = useState(true);
  const [getThreatList, { error: errorGetThreatList }] = useLazyQuery(
    GET_THREATLIST,
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    getThreatList({ variables: { filter: "", page_index: 0, limit: 1 } });
  }, [getThreatList]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);

    if (!errorGetThreatList) {
      return;
    }

    dispatch(logout());
    toast.custom(
      <div
        className="alert alert-light d-flex align-items-center justify-content-start shadow-sm"
        style={{ width: "350px" }}
      >
        <div>
          <FontAwesomeIcon
            icon="check-circle"
            size="2x"
            className="me-3 text-vsepr"
          />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-vsepr">
            You have been logged out due to inactivity.
          </h6>
        </div>
      </div>
    );
    navigate("/login");
  }, [errorGetThreatList, dispatch, navigate]);

  if (loading) {
    return <div />;
  }

  return (
    <>
      <div id="main">
        <nav className="bg-vseprlight d-flex" style={{ height: "75px" }}>
          {/* Top Navbar */}
          <div className="container-fluid d-flex flex-grow-1 bg-vseprlight" style={{ zIndex: 1 }}>
            <div className="row no-gutters d-flex flex-grow-1 align-items-center">
              {/* Logo and brand */}
              <div className="col d-flex align-items-center">
                <div className="d-inline-block">
                  <NavLink to="/" className="navbar-brand">
                    <img alt="Project VSEPR" src={logo} width="100" />
                  </NavLink>
                </div>
                <div className={`d-inline-block text-center px-3 ${!isSidebarCollapsed ? 'none-collapsed' : ''}`}>
                  <button className="btn border-0" onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
                    <FontAwesomeIcon className="border text-vseprdark border-vseprmenuactive rounded-0 p-1" icon={isSidebarCollapsed ? "chevron-right" : "chevron-left"} size={"xs"} />
                  </button>
                </div>
              </div>
              {/* User Actions */}
              <div className="col d-flex align-items-center justify-content-end">
                <div className="d-none d-md-block bg-white me-2">
                  <button type="button" className="btn btn-sm btn-outline-vsepr px-2 py-1 rounded-1 btn-network">
                    <img alt="" src={MIB_ASE_NetworkIcon} className="me-2" />
                    {/* HQ Network */}
                    MIB ASE Network
                    <FontAwesomeIcon icon="chevron-down" size="xs" className="ms-4" />
                  </button>
                </div>

                {/* <div className="d-none d-md-block">
                  <button type="button" className="btn px-2">
                    <img alt="" src={iconApp} />
                  </button>
                </div> */}

                <button type="button" id="userPopoverButton" className="btn">
                  {/* <Avatar name={username} size="30" round={true} /> */}
                  <img alt="" src={MaskGroup} className="me-2" />
                  <FontAwesomeIcon icon="chevron-down" size="sm" className="ms-2" />
                </button>
                <Popover placement="bottom-end" isOpen={isUserPopoverOpen} target="userPopoverButton" toggle={() => { setIsUserPopoverOpen(!isUserPopoverOpen); }} trigger="legacy">
                  <PopoverBody style={{ width: '280px' }}>
                    <div className="d-flex align-items-start">
                      <div className="me-2">
                        <img alt="" src={MaskGroup} className="me-2" />
                        {/* <Avatar name={username} size="45" round={true} /> */}
                      </div>
                      <div>
                        <h6 className="mb-1">Hello {username}!</h6>
                        <p>Welcome to Project VSEPR</p>
                      </div>
                    </div>

                    <div className="d-flex align-items-start">
                      {role === ROLES.ADMIN &&
                        <NavLink to="/admin" className="btn btn-sm btn-light me-2">Go to Admin</NavLink>
                      }
                      <button type="button" className="btn btn-sm btn-vsepr" onClick={logOut}>Logout</button>
                    </div>
                  </PopoverBody>
                </Popover>
              </div>
            </div>
          </div>
        </nav>
        <div className="container-fluid d-flex" style={{ height: `calc(100vh - 75px)` }}>
          <div className="row no-gutters flex-grow-1 bg-vseprlight">
            {/* <div className={`sidebar bg-vseprlight p-0 ${isSidebarCollapsed ? 'collapsed' : 'none-collapsed'}`}>
              {renderNav()}
            </div> */}
            <div
              className={`sidebar bg-vseprlight p-0 ${isSidebarCollapsed ? 'collapsed' : 'none-collapsed'}`}
              style={{
                transition: 'all .2s ease-in-out', // Smooth animation for width
                // width: isSidebarCollapsed ? '60px' : '250px', // Adjust the width dynamically
              }}
            >
              {renderNav()}
            </div>
            <div className="col bg-white p-0" style={{ borderRadius: isSidebarOpen ? '20px 0 0 0' : '0px', width: '0px' }}>
              <div className="content-wrapper flex-grow-1 bg-white p-0" style={{ borderRadius: '20px 0 0 0' }}>
                <div className="main">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* Main content layout */}
      {/* <div className="d-flex ">

        <div className="content-wrapper flex-grow-1 bg-white p-0" style={{ borderRadius: '20px 0 0 0' }}>
          <div className="main">
            <Outlet />
          </div>
        </div>
      </div> */}
    </>
  );
}


function SuperAdminLayout() {

  const dispatch = useDispatch()

  const username = useSelector(state => state.app.username);

  const [isUserPopoverOpen, setIsUserPopoverOpen] = useState(false);

  const logOut = async () => {
    try {
      await axiosInstance({
        url: `${APIENDPOINT}/logout`,
        method: 'POST'
      });
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-vsepr" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-vsepr">Successfully Logged Out</h6>
        </div>
      </div>);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(logout());
    }
  };

  return (
    <div id="main">
      <nav className="d-flex" style={{ height: '75px' }}>
        <div className="container-fluid d-flex flex-grow-1">
          <div className="row no-gutters d-flex flex-grow-1 align-items-center">
            <div className="bg-vseprdarker col-2 h-100 d-flex align-items-center">
              <div className="d-inline-block">
                <NavLink to="/admin" className="navbar-brand">
                  <img alt="Project VSEPR: Admin" src={logoAdmin} width="200" />
                </NavLink>
              </div>
            </div>
            <div className="col d-flex align-items-center justify-content-end h-100 bg-light">
              <div className="d-inline-block me-2">
                <button type="button" className="btn btn-sm btn-outline-secondary px-2 py-1 rounded-1 btn-network">
                  <img alt="" src={networkIcon} className="me-2" />
                  HQ Network

                  <FontAwesomeIcon icon="chevron-down" size="xs" className="ms-4" />
                </button>
              </div>

              <div className="d-inline-block">
                <button type="button" className="btn px-2">
                  <img alt="" src={iconApp} />
                </button>
              </div>

              <button type="button" id="userPopoverButton" className="btn">
                <Avatar name={username} size="30" round={true} />
                <FontAwesomeIcon icon="chevron-down" size="sm" className="ms-2" />
              </button>
              <Popover placement="bottom-end" isOpen={isUserPopoverOpen} target="userPopoverButton" toggle={() => { setIsUserPopoverOpen(!isUserPopoverOpen); }} trigger="legacy">
                <PopoverBody style={{ width: '280px' }}>
                  <div className="d-flex align-items-start">
                    <div className="me-2">
                      <Avatar name={username} size="45" round={true} />
                    </div>
                    <div>
                      <h6 className="mb-1">Hello {username}!</h6>
                      <p>Welcome to Project VSEPR</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start">
                    <NavLink to="/" className="btn btn-sm btn-light me-2">Go to Portal</NavLink><br />
                    <button type="button" className="btn btn-sm btn-vsepr" onClick={logOut}>Logout</button>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid d-flex" style={{ height: `calc(100vh - 75px)` }}>
        <div className="row no-gutters flex-grow-1 bg-vseprdarker">
          <div className="col-2 p-0">
            <nav className="nav flex-column mt-4 nav-admin">
              <li className="nav-item">
                <span className="text-white nav-link fw-bold text-uppercase py-2">Access Control</span>
              </li>
              <li className="nav-item">
                <NavLink to="/admin/accesscontrol" className={({ isActive }) => `btn btn-outline-light rounded-0 text-start nav-link py-3 d-flex align-items-center  ${isActive ? 'active bg-light' : ''}`}>
                  <img alt="" src={iconUser} className="me-2" />
                  <span>Users</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <span className="text-white nav-link fw-bold text-uppercase py-2">Integrations</span>
              </li>
              <li className="nav-item">
                <NavLink to="/admin/integrations" className={({ isActive }) => `btn btn-outline-light rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active bg-light' : ''}`}>
                  <img alt="" src={iconLink} className="me-2" />
                  <span>Data and Connections</span>
                </NavLink>
              </li>
            </nav>
          </div>
          <div className="col bg-light p-0" style={{ borderRadius: '0px' }}>
            <div className="main">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
function ReportBuilderComponents() {
  return (
    <>
      <Outlet />
    </>
  )
}

const routes = (isSignedIn, role) => [
  {
    element: <Layout />,
    children: [
      { path: '/login', exact: true, name: 'Login', element: !isSignedIn ? <Login /> : <Navigate to="/" /> },
      // { path: '/packetbuilderprintable', name: 'PacketBuilderPrintable', element: isSignedIn ? <PacketBuilderPrintable /> : <Navigate to="/login" /> },
      { path: '/playground/dnd', name: 'PlaygroundDnD', element: isSignedIn ? <DndApp /> : <Navigate to="/login" /> },
      {
        element: <AdminLayout />,
        children: [
          { path: '/', index: true, exact: true, name: 'Dashboard', element: isSignedIn ? <Dashboard /> : <Navigate to="/login" /> },
          { path: '/sources/*', name: 'Sources', element: isSignedIn ? <Sources /> : <Navigate to="/login" /> },
          { path: '/analysis/*', name: 'Analysis', element: isSignedIn ? <Analysis /> : <Navigate to="/login" /> },
          { path: '/dataextraction/*', name: 'DataExtraction', element: isSignedIn ? <DataExtraction /> : <Navigate to="/login" /> },
          { path: '/governance/*', name: 'Governance', element: isSignedIn ? <Governance /> : <Navigate to="/login" /> },
        ]
      },
      {
        element: <SuperAdminLayout />,
        children: [
          { path: '/admin/*', name: 'Admin', element: isSignedIn ? role === ROLES.ADMIN ? <Admin /> : <Navigate to="/" /> : <Navigate to="/login" /> },
        ]
      },
      {
        element: <ReportBuilderComponents />,
        children: [
          { path: '/reportbuilderiframecomponent/*', name: 'ReportBuilderIframeComponent', element: isSignedIn ? <ReportBuilderIframeComponent /> : <Navigate to="/" /> },
        ]
      },
    ]
  }
]

function App() {

  const isSignedIn = useSelector(state => state.app.isSignedIn);
  const role = useSelector(state => state.app.role);
  const routing = useRoutes(routes(isSignedIn, role))
  return routing;

}

export default App;
